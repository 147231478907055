
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { loadUser, updateProfile } from '../slices/userSlice'
import { useDispatch, useSelector } from 'react-redux';


const Profile = ({ userData }) => {
    const [name, setName] = useState(userData?.name)
    const [rank, setRank] = useState(userData?.rank)
    const [password, setPassword] = useState("")

    const [message, setMessage] = useState("")
    const [messageType, setMessageType] = useState("")


    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { isUpdateSuccess, isUpdateFailed } = useSelector((state) => state.userCustom)

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(updateProfile({ name, rank, password })).then(() => dispatch(loadUser()))
    }

    useEffect(() => {
        if (isUpdateFailed) {
            setMessage('Profile Updation Failed !');
            setMessageType('error');
        } else if (isUpdateSuccess) {
            setMessage('Profile Updated !');
            setMessageType('success')
        }

        // Clear the message after 2 seconds
        const timeout = setTimeout(() => {
            setMessage('');
            setMessageType('')
        }, 2000);

        return () => clearTimeout(timeout); // Clear the timeout on unmount or effect cleanup

    }, [isUpdateFailed, isUpdateSuccess]);


    return (
        <div className="content-wrapper">

            <section className="content">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Profile</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <p className='cursor-pointer text-[#D1A4AC]' onClick={() => navigate(`/${userData?.role}/dashboard`)}>Home</p>
                                    </li>
                                    <li className="breadcrumb-item active">Profile</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                <div className="container full-width welcome-box-item-container">
                    <div className="row">
                        <div className="container">
                            <div className="container-fluid">
                                <section className="content">
                                    <div className="container">
                                        <div className="container-fluid">
                                            <div className="col-md-12">
                                                <div className="card card-primary">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Update Profile</h3>
                                                    </div>
                                                    <form
                                                        id="customerUpload"
                                                        name="customerUpload"
                                                        method="post"
                                                        encType="multipart/form-data"
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="form-group col-sm-6">
                                                                    <label htmlFor="name">Name</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="name"
                                                                        placeholder="Enter Name"
                                                                        name="name"
                                                                        value={name}
                                                                        onChange={(e) => setName(e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className="form-group col-sm-6">
                                                                    <label htmlFor="email">Email</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="email"
                                                                        placeholder="Enter Email"
                                                                        name="email"
                                                                        readOnly
                                                                        defaultValue={userData?.email}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group col-sm-6">
                                                                    <label htmlFor="plan">Plan</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="plan"
                                                                        placeholder="Your Current Plan"
                                                                        name="plan"
                                                                        readOnly
                                                                        defaultValue={userData?.plan}
                                                                    />
                                                                </div>
                                                                <div className="form-group col-sm-6">
                                                                    <label htmlFor="plexus_ambassador_id">Plexus Ambassador Id</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="plexus_ambassador_id"
                                                                        placeholder="Your Plexus Ambassador Id"
                                                                        name="plexus_ambassador_id"
                                                                        readOnly
                                                                        defaultValue={userData?.plexusAmbassadorId}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="form-group col-sm-6">
                                                                    <label htmlFor="rank">Rank</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="rank"
                                                                        placeholder="Enter Rank"
                                                                        name="rank"
                                                                        value={rank}
                                                                        onChange={(e) => setRank(e.target.value)}

                                                                    />
                                                                </div>
                                                                <div className="form-group col-sm-6">
                                                                    <label htmlFor="password">Password</label>
                                                                    <input
                                                                        type="password"
                                                                        className="form-control"
                                                                        id="password"
                                                                        placeholder="Enter Password"
                                                                        name="password"
                                                                        value={password}
                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                    />
                                                                    <code>Leave blank if you don't want to change it</code>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="card-footer">
                                                            <button type="submit" className="btn btn-primary">
                                                                Submit
                                                            </button>
                                                            <div className={`text-${messageType === 'success' ? 'green' : 'red'}`}>
                                                                {message}
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>



                        {/* Continue rendering the other sections... */}
                    </div>
                </div>
            </section>
        </div>

    )
}

export default Profile


