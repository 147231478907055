import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Sidebar = ({ userData }) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [active, setActive] = useState(false)

  return (
    <aside className="main-sidebar h-[100vh] sidebar-dark-primary elevation-4">
      <a className="brand-link">

        <span style={{ textAlign: "center", display: "block", fontFamily: "Lucida Calligraphy" }} className="brand-text font-weight-light ">The Analytical Advantage</span>
      </a>
      <div className="sidebar">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">


            <li className="nav-item " onClick={() => navigate("dashboard")}>
              <a className="nav-link active " >
                <i className="nav-icon fas fa-tachometer-alt text-white"></i>
                <p className='text-white'>Dashboard</p>
              </a>
            </li>
            {userData?.role === "admin" && (
              <li className="nav-item" onClick={() => { navigate("users"); setActive(!active) }}>
                <a className={`nav-link `}>
                  <i className="nav-icon fas fa-user text-white"></i>
                  <p className='text-white'>Users</p>
                </a>
              </li>
            )}


            <li className="nav-item">
              <div className={!open ? 'nav-link' : 'nav-link nav-item menu-is-opening menu-open'} onClick={() => setOpen(!open)}>
                <i className="nav-icon fas fa-copy text-white"></i>
                <p className='text-white'>
                  Importer <i className="fas fa-angle-left right "></i>
                  <span className="badge badge-info right"></span>
                </p>
              </div>
              <ul className="nav nav-treeview" style={{ display: open ? 'block' : 'none' }}>
                {/* <li className="nav-item" onClick={() => navigate("report/pv")}>
                  <a className="nav-link ">
                    <p className='text-white'>PV Report</p>
                  </a>
                </li> */}
                {/* <li className="nav-item" onClick={() => navigate("report/genealogy")}>
                  <a className="nav-link ">
                    <p className='text-white'>Genealogy</p>
                  </a>
                </li> */}
                <li className="nav-item" onClick={() => navigate("report/points&rankup")}>
                  <a className="nav-link ">
                    <p className='text-white'>Points and Rank Up</p>
                  </a>
                </li>

                {/* <li className="nav-item" onClick={() => navigate("report/rankAdvancement")}>
                  <a className="nav-link ">
                    <p className='text-white'> Rank Advancement</p>
                  </a>
                </li> */}


              </ul>
            </li>



          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
