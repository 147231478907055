import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import "../custom.css"
import { logout } from '../slices/userSlice';
import { Link, useNavigate } from 'react-router-dom';

const Navbar = ({ userData }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const handleMenuToggle = () => {
    setIsMenuOpen(prevIsMenuOpen => !prevIsMenuOpen); // Updated line
  };
  const handleMenuToggle2 = () => {
    setOpen(!open); // Updated line
  };

  const handleSignOut = () => {
    dispatch(logout()).then(() => navigate("/"))
  };

  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" role="button" onClick={handleMenuToggle}>
            <i className="fas fa-bars text-[#D1A4AC]"></i>
          </a>
        </li>
      </ul>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown user-menu">
          <div className="nav-link dropdown-toggle cursor-pointer" onClick={handleMenuToggle2}>
            <span style={{ fontFamily: "Lucinda calligraphy", textTransform: "capitalize", fontSize: "15px" }} className="d-none d-md-inline text-[#D1A4AC] ">{userData?.name ? userData.name : userData?.email} <i className="fa-solid fa-caret-down"></i></span>
          </div>
          {open && (
            <ul className="dashboard-dropdown">
              {/* User image */}
              <li className="p-3 bg-[#D1A4AC]">
                <p>
                  {userData?.name ? userData.name : userData?.email}
                </p>
              </li>
              {/* Menu Footer */}
              <li className="p-2">
                {userData?.role === "primeUser" ?
                  <p onClick={() => { navigate(`/user/profile`); setOpen(false) }} className="btn btn-default btn-flat">
                    Profile
                  </p> :
                  <p onClick={() => { navigate(`/${userData.role}/profile`); setOpen(false) }} className="btn btn-default btn-flat">
                    Profile
                  </p>
                }

                <p className="btn btn-default btn-flat float-right" onClick={() => handleSignOut()}>
                  Sign out
                </p>
                <form
                  id="logout-form"
                  action="https://custom-insight.dawntillduskassisting.com/logout"
                  method="POST"
                  className="d-none"
                >
                  <input type="hidden" name="_token" value="TnreQCyQdobinyNi25349otgAWYs1BD7XWccySHI" />
                </form>
              </li>
            </ul>
          )}
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
