import React, { useEffect, useState } from "react";
import axios from "axios";
import { Chart } from "chart.js";
import BarChartComponent from "./BarChartComponent";
import CircleChart from "./CircleChart";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import "../index.css"

import { useDispatch, useSelector } from "react-redux";
import { getGenealogyReportData, getOrderReportFullYear, getPointsAndRankupReportData, getPvReportData, getRankAdvancementReportData } from "../slices/PdfFilesSlice";
import { generateCSVForPvReport, generateCSVForRetails, generateCSVForCurrentPoints, generateCSVForBrandAmbassadors, generateCSVForLvl2, generateCSVForLvl1, generateCSVForNewRanksReport, generateCSVForRecruitersReport, generateCSVForTopLeaderWithNewBrandAmbassadors, generateCSVForPointsPerLeg, generateCSVForCreditCardDeclineReport, generateCSVForMonthlyOrdersReport, generateCSVForlvl1Vips, generateCSVForlvl2PlusVips } from "../generate";


const Dashboard = ({ userData }) => {
    const dispatch = useDispatch()


    const [currentPv, setCurrentPv] = useState(null);
    const [newRetails, setNewRetails] = useState(null);
    const [selectedLevel, setSelectedLevel] = useState('level1'); // 'level1' or 'level2'


    const [newRanks, setNewRanks] = useState(null);

    const [currentPoints, setCurrentPoints] = useState(null);

    const [lvl1Vips, setLvl1Vips] = useState(null);
    const [lvl2PlusVips, setLvl2PlusVips] = useState(null);
    const [lvl1VipsReport, setLvl1VipsReport] = useState(null);
    const [lvl2PlusVipsReport, setLvl2PlusVipsReport] = useState([]);


    const [CureVIPS, setVIPS] = useState(null);


    const [totalCurMonthPersonalVolume, setTotalCurMonthPersonalVolume] = useState(null);
    const [totalPrevMonthPersonalVolume, setTotalPrevMonthPersonalVolume] = useState(null);



    const [curMonthVipsCounts,setCurMonthVipsCounts] = useState(null);
    const [prevMonthVipsCounts,setPrevMonthVipsCounts] = useState(null);
    const [curMonthVipsPoints,setCurMonthVipsPoints] = useState(null);
    const [prevMonthVipsPoints,setPrevMonthVipsPoints] = useState(null);

    const [newBrandAmbassdors, setNewBrandAmbassdors] = useState(null);
    const [creditCardDeclineRate, setCreditCardDeclineRate] = useState(null);

    const [monthlyOrdersReport, setMonthlyOrdersReport] = useState([]);


    const [showMore, setShowMore] = useState(false);
    const [showMore2, setShowMore2] = useState(false);
    const [leadersboard, setLeadersboard] = useState([]);
    const [visibleLeadersboard, setVisibleLeadersboard] = useState([]);
    const [visibleLeg, setVisibleLeg] = useState([]);
    const [legs, setLegs] = useState([])

    const [forecastedEstimate, setForecastedEstimate] = useState(null)
    const [forecastedLow, setForecastedLow] = useState(null)
    const [forecastedHigh, setForecastedHigh] = useState(null)
    const [fullyCompressedPoints, setFullyCompressedPoints] = useState(null)
    const [pointsFromSubscription, setPointsFromSubscription] = useState(null)
    const [averageCompressionBonus, setAverageCompressionBonus] = useState(null)
    const [declineRate, setDeclineRate] = useState(null)
    const [subscriptionsOnlyEstimate, setSubscriptionsOnlyEstimate] = useState(null)
    const [pointsGainedLastMonthWithoutSubscriptions, setPointsGainedLastMonthWithoutSubscriptions] = useState(null)
    const [pointsGainedThisMonthWithoutSubscriptions, setPointsGainedThisMonthWithoutSubscriptions] = useState(null)
    const [estimatedPointsComingFromDiscretionaryOrders, setEstimatedPointsComingFromDiscretionaryOrders] = useState(null)
    const [estimatedPointsComingFromNewVIPs, setEstimatedPointsComingFromNewVIPs] = useState(null)


    // for report Download
    const [pvReport, setPvReport] = useState([])
    const [retailsReport, setRetailsReport] = useState([])
    const [currentPointsReport, setCurrentPointsReport] = useState([])

    const [newBrandAmbassdorsReport, setNewBrandAmbassdorsReport] = useState([])
    const [topLeadersWithNewBrandAmbassdorsReport, setTopLeadersWithNewBrandAmbassdorsReport] = useState([])
    const [recruitersReport, setRecruitersReport] = useState([])
    const [newRanksReport, setNewRanksReport] = useState([])
    const [pointsPerLegReport, setPointsPerLegReport] = useState([])
    const [ccDeclineReport, setCcDeclineReport] = useState([])
    const [lastMonthRetentions, setLastMonthRetentions] = useState([])
    const [currentMonthRetentions, setCurrentMonthRetentions] = useState([])
    const [previousMonthOrders, setPreviousMonthOrders] = useState(0)
    const [ordersPreviousMonthsCount, setOrdersPreviousMonthsCount] = useState(0)

    const { PvReportData, RankAdvancementReportData, GenealogyReportData, PointsAndRankupReportData, YearlyReportData } = useSelector((state) => state.customPdf)

    const handleShowMoreClick = () => {
        if (showMore) {
            setVisibleLeadersboard(recruitersReport.slice(0, 5));
        } else {
            setVisibleLeadersboard(recruitersReport.slice(0, 10));
        }
        setShowMore(!showMore);
    };
    const handleShowMoreClick2 = () => {
        if (showMore2) {
            setVisibleLeg(legs.slice(0, 5));
        } else {
            setVisibleLeg(legs);
        }
        setShowMore2(!showMore2);
    };


    useEffect(() => {

        dispatch(getPvReportData())
        dispatch(getRankAdvancementReportData())
        dispatch(getGenealogyReportData())
        dispatch(getPointsAndRankupReportData())
        dispatch(getOrderReportFullYear())
    }, [dispatch])

    useEffect(() => {
        if (PvReportData) {
            setCurrentPv(PvReportData.currentPv)
            setNewRetails(PvReportData.newRetails)
            setPvReport(PvReportData.pvReport)
            setRetailsReport(PvReportData.retailsReport)
        }
        if (RankAdvancementReportData) {
            setNewRanks(RankAdvancementReportData.newRanks)
            setNewRanksReport(RankAdvancementReportData.newRanksReport)
        }
        if (GenealogyReportData) {
            setCurrentPoints(GenealogyReportData.currentPoints)


        }
        if (PointsAndRankupReportData) {
            setCreditCardDeclineRate(PointsAndRankupReportData.ccDeclineRate)
            setLeadersboard(PointsAndRankupReportData.topLeadersWithNewAmbassadorsReport)
            setLegs(PointsAndRankupReportData.top8RowsLegsReport)
            setForecastedLow(PointsAndRankupReportData.ForecastedLow)
            setForecastedHigh(PointsAndRankupReportData.ForecastedHigh)
            setForecastedEstimate(PointsAndRankupReportData.ForecastedEstimate)
            setFullyCompressedPoints(PointsAndRankupReportData.fullyCompressedPoints)
            setAverageCompressionBonus(PointsAndRankupReportData.averageCompressionBonus)
            setPointsFromSubscription(PointsAndRankupReportData.totalPointsFromSubscription)
            setDeclineRate(PointsAndRankupReportData.forcastingDeclineRate)
            setSubscriptionsOnlyEstimate(PointsAndRankupReportData.subscriptionOnlyEstimate)
            setPointsGainedLastMonthWithoutSubscriptions(PointsAndRankupReportData.pointsGainedLastMonthWithoutSubs)
            setPointsGainedThisMonthWithoutSubscriptions(PointsAndRankupReportData.pointsGainedThisMonthWithoutSubs)
            setEstimatedPointsComingFromDiscretionaryOrders(PointsAndRankupReportData.estimatedPointsComingFromDiscretionaryOrders)
            setEstimatedPointsComingFromNewVIPs(PointsAndRankupReportData.estimatedPointsComingFromNewVips)
            setForecastedHigh(PointsAndRankupReportData.ForecastedHigh)
            setForecastedLow(PointsAndRankupReportData.ForecastedLow)
            setVIPS(PointsAndRankupReportData.newVipsTotal)

            setTotalCurMonthPersonalVolume(PointsAndRankupReportData.totalCurMonthPersonalVolume)
            setTotalPrevMonthPersonalVolume(PointsAndRankupReportData.totalPrevMonthPersonalVolume)



            setCurMonthVipsCounts(PointsAndRankupReportData.newVipsTotal)
            setCurMonthVipsPoints(PointsAndRankupReportData.pointsFromNewVips)
            setPrevMonthVipsCounts(PointsAndRankupReportData.lastMonthVipsTotal)
            setPrevMonthVipsPoints(PointsAndRankupReportData.lastMonthPointsFromNewVips)


            setNewBrandAmbassdors(PointsAndRankupReportData.newBrandAmbassadors)
            setNewBrandAmbassdorsReport(PointsAndRankupReportData.newBrandAmbassadorReport)
            setTopLeadersWithNewBrandAmbassdorsReport(PointsAndRankupReportData.topLeadersWithNewAmbassadorsReport)
            setRecruitersReport(PointsAndRankupReportData.topRecruitersReport)
            setCcDeclineReport(PointsAndRankupReportData.filterdDataForCreditCardReport)
            setCurrentPoints(PointsAndRankupReportData.totalCurrentPoints)
            setCurrentPointsReport(PointsAndRankupReportData.currentPointsReport)
            setMonthlyOrdersReport(PointsAndRankupReportData.ordersMonthlyReport)
            setPreviousMonthOrders(PointsAndRankupReportData.previousMonthOrders)
            setLastMonthRetentions(PointsAndRankupReportData.lastMonthRetention)
            setCurrentMonthRetentions(PointsAndRankupReportData.currentMonthRetention)

            setLvl1Vips(PointsAndRankupReportData.level1VipsCount)
            setLvl2PlusVips(PointsAndRankupReportData.level2PlusVipsCount)
            setLvl1VipsReport(PointsAndRankupReportData.Lvl1VipsReport)
            setLvl2PlusVipsReport(PointsAndRankupReportData.Lvl2PlusVipsReport)
            setOrdersPreviousMonthsCount(PointsAndRankupReportData.ordersPreviousMonthsCount)

            const legNamesArrayFilteredData = PointsAndRankupReportData.legNamesArrayReport.filter(item => item.PlexusPointsInOrganization !== 0);
            setPointsPerLegReport(legNamesArrayFilteredData)
        }

    }, [PointsAndRankupReportData, GenealogyReportData, RankAdvancementReportData, PvReportData, YearlyReportData])

    useEffect(() => {

        if (leadersboard.length > 0) {
            setVisibleLeadersboard(recruitersReport.slice(0, 5));
        }
        if (legs.length > 0) {
            setVisibleLeg(legs.slice(0, 5));
        }
    }, [leadersboard, legs]);

    const handlePvReportDownload = () => {
        if (pvReport) {
            generateCSVForPvReport(pvReport)
        }
    }
    const handleNewRetailsReportDownload = () => {
        if (retailsReport) {
            generateCSVForRetails(retailsReport)
        }
    }
    const handleCurrentPointsReportDownload = () => {
        if (currentPointsReport) {
            generateCSVForCurrentPoints(currentPointsReport)
        }
    }

    const handleForBrandAmbassadorsReportDownload = () => {
        if (newBrandAmbassdorsReport) {
            generateCSVForBrandAmbassadors(newBrandAmbassdorsReport)
        }
    }
    const handleMonthlyOrderReportDownload = () => {
        if (monthlyOrdersReport) {
            generateCSVForMonthlyOrdersReport(monthlyOrdersReport)
        }
    }
    const handleDownloadCsvForLeadersboard = () => {
        if (newBrandAmbassdorsReport && recruitersReport) {
            generateCSVForTopLeaderWithNewBrandAmbassadors(topLeadersWithNewBrandAmbassdorsReport)
            generateCSVForRecruitersReport(recruitersReport)

        }
    }
    const handleDownloadCsvForPointsPerLeg = () => {
        if (pointsPerLegReport) {
            generateCSVForPointsPerLeg(pointsPerLegReport)

        }
    }
    const handleLvl2PlusReportDownload = () => {
        if (lvl2PlusVipsReport) {
            generateCSVForlvl2PlusVips(lvl2PlusVipsReport)

        }
    }
    const handleLvl1ReportDownload = () => {
        if (lvl1VipsReport) {
            generateCSVForlvl1Vips(lvl1VipsReport)

        }
    }
    const handleForNewRanksReportDownload = () => {
        if (newRanksReport) {
            generateCSVForNewRanksReport(newRanksReport)
        }
    }
    const handleCCDeclineReportDownload = () => {
        if (ccDeclineReport) {
            generateCSVForCreditCardDeclineReport(ccDeclineReport)
        }
    }


    // toogle
    const handleToggle = () => {
        setSelectedLevel(selectedLevel === 'level1' ? 'level2' : 'level1');
    };
    const vipsData = selectedLevel === 'level1' ? lvl1Vips : lvl2PlusVips;

    const handleReportDownload = () => {
        if (selectedLevel === 'level1') {
            handleLvl1ReportDownload();
        } else {
            handleLvl2PlusReportDownload();
        }
    };


    return (
        <div className="content-wrapper">
            <section className="content">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>My Dashboard</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <p className="text-[#D1A4AC]">Home</p>
                                    </li>
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                <div className="container full-width welcome-box-item-container ">
                    <div className="row ">
                        <div className="col-lg-12 welcome-txt bg-[#ffffff]">
                            <div
                                className="text-center bg-[#ffE9ED]"
                                style={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}
                            >
                                <div></div>
                                <div className="" style={{ textAlign: "center", paddingLeft: "15px" }}>
                                    <h2 className="sponsor-name">{userData?.name ? userData.name : userData?.email}</h2>
                                    <span style={{ fontSize: "24px", lineHeight: "30px", fontWeight: "700" }}></span>
                                    <p style={{ fontSize: "20px", lineHeight: "26px", fontWeight: "500" }}>
                                        Welcome to your Analytical Advantage Dashboard! <br /> Wish It! Plan It! Achieve It!
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className="row w-full">
                            <div style={{ border: "1px solid #ffffff", background: "#ffffff" }} className="col-lg-12 pt-2 px-3">

                                <div className="row ">
                                    <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className=" col-lg-2 p-1">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED", height: "180px", display: "flex", flexDirection: "column", justifyContent: "center" }} className="relative">
                                            <h3 style={{ fontSize: "15px", fontWeight: "600" }} className="text-center">Current Points</h3>
                                            <p style={{ fontSize: "40px", color: "#D1A4AC", fontWeight: "600" }} className="text-center cursor-pointer">{!currentPoints ? 0 : currentPoints}</p>
                                            <i onClick={handleCurrentPointsReportDownload} style={{ position: "absolute", top: "80%", right: "5%", transform: "tra", color: "#D1A4AC" }} className="fa-solid fa-download text-md cursor-pointer"></i>
                                        </div>
                                    </div>
                                 
                                        <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className="col-lg-2 p-1">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED", height: "180px", display: "flex", flexDirection: "column", justifyContent: "center" }} className="">
                                            <h3 style={{ fontSize: "15px", fontWeight: "600" }} className="text-center">New Brand Ambassadors</h3>
                                            <p style={{ fontSize: "40px", color: "#D1A4AC", fontWeight: "600" }} className="text-center cursor-pointer">{!newBrandAmbassdors ? 0 : newBrandAmbassdors}</p>
                                            <i onClick={handleForBrandAmbassadorsReportDownload} style={{ position: "absolute", top: "80%", right: "5%", transform: "tra", color: "#D1A4AC" }} className="fa-solid fa-download text-md cursor-pointer"></i>
                                        </div>
                                    </div>

                                    {/* <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className="col-lg-4 p-1">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED" }} className="">
                                            <CircleChart />

                                        </div>
                                    </div> */}
                                    <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className=" col-lg-4 p-1">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED", height: "180px", display: "flex", flexDirection: "column", justifyContent: "center" }} className="py-3">
                                            <h3 style={{ fontSize: "15px", fontWeight: "600" }} className="text-center">Month 2 Retention</h3>
                                            <ul className="flex w-[70%]  m-auto justify-between items-center">
                                                <ul>
                                                    <li style={{ fontSize: "14px" }}>Last Month</li>
                                                    <li style={{ fontSize: "25px", color: "#D1A4AC", fontWeight: "600" }} className="text-center"> {lastMonthRetentions[new Date().getMonth()-1] ? lastMonthRetentions[new Date().getMonth()-1].toFixed() + '%' : 'N/A'}</li>
                                                </ul>
                                                <ul>
                                                    <li style={{ fontSize: "14px" }}>This Month</li>
                                                    {/* <li style={{ fontSize: "25px", color: "#D1A4AC", fontWeight: "600" }} className="text-center"> {currentMonthRetentions[new Date().getMonth() + 1] ? currentMonthRetentions[new Date().getMonth()].toFixed() + '%' : 'N/A'}</li> */}
                                                    <li style={{ fontSize: "25px", color: "#D1A4AC", fontWeight: "600" }} className="text-center"> {currentMonthRetentions[new Date().getMonth() + 1] ? currentMonthRetentions[new Date().getMonth()].toFixed() + '%' : 'N/A'}</li>
                                                </ul>
                                            </ul>
                                            {/* <i onClick={handleMonthlyOrderReportDownload} style={{ position: "absolute", top: "80%", right: "5%", transform: "tra", color: "#D1A4AC" }} className="fa-solid fa-download text-md cursor-pointer"></i> */}
                                        </div>
                                    </div>

                                    <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "auto" }} className="col-lg-4 p-1">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED" }} className="py-3">
                                            <h3 style={{ fontSize: "15px", fontWeight: "600" }} className="text-center">Current Forecasted Points</h3>
                                            <p style={{ fontSize: "40px", color: "#D1A4AC", fontWeight: "600" }} className="text-center cursor-pointer">{!forecastedEstimate ? 0 : `${forecastedEstimate.toString().split('.')[0]}`} </p>
                                            <div className="arrow-container">
                                                <p className="arrow-text">
                                                    <i className="arrow fa-solid fa-arrow-down"></i>
                                                </p>
                                            </div>
                                            <div className="p-3">
                                                <div className="flex items-center gap-4 py-1">
                                                    <div className="relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] after:h-4/6">

                                                        <i className="fa-solid fa-circle text-[#D1A4AC]"></i>
                                                    </div>
                                                    <div style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }} className="d-flex">
                                                        <div className="antialiased font-sans text-sm leading-normal text-[#000000]  font-medium">
                                                            Forecasted Low
                                                        </div>
                                                        <div className=" antialiased font-sans text-sm font-medium text-blue-gray-500">
                                                            {!forecastedLow ? 0 : `${forecastedLow.toString().split('.')[0]}`}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-4 py-1">
                                                    <div className="relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] after:h-4/6">

                                                        <i className="fa-solid fa-circle text-[#D1A4AC]"></i>
                                                    </div>
                                                    <div style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }} className="d-flex">
                                                        <div className="antialiased font-sans text-sm leading-normal text-[#000000]  font-medium">
                                                            Forecasted High
                                                        </div>
                                                        <div className=" antialiased font-sans text-sm font-medium text-blue-gray-500">
                                                            {!forecastedHigh ? 0 : `${forecastedHigh.toString().split('.')[0]}`}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-4 py-1">
                                                    <div className="relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] after:h-4/6">

                                                        <i className="fa-solid fa-circle text-[#D1A4AC]"></i>
                                                    </div>
                                                    <div style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }} className="d-flex">
                                                        <div className="antialiased font-sans text-sm leading-normal text-[#000000]  font-medium">
                                                            Fully Compressed Points
                                                        </div>
                                                        <div className=" antialiased font-sans text-sm font-medium text-blue-gray-500">
                                                            {!fullyCompressedPoints ? 0 : `${fullyCompressedPoints.toString().split('.')[0]}`}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-4 py-1">
                                                    <div className="relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] after:h-4/6">

                                                        <i className="fa-solid fa-circle text-[#D1A4AC]"></i>
                                                    </div>
                                                    <div style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }} className="d-flex">
                                                        <div className="antialiased font-sans text-sm leading-normal text-[#000000]  font-medium">
                                                            Points from Subscription
                                                        </div>
                                                        <div className=" antialiased font-sans text-sm font-medium text-blue-gray-500">
                                                            {!pointsFromSubscription ? 0 : `${pointsFromSubscription.toString().split('.')[0]}`}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-4 py-1">
                                                    <div className="relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] after:h-4/6">

                                                        <i className="fa-solid fa-circle text-[#D1A4AC]"></i>
                                                    </div>
                                                    <div style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }} className="d-flex">
                                                        <div className="antialiased font-sans text-sm leading-normal text-[#000000]  font-medium">
                                                            Average Compression Bonus
                                                        </div>
                                                        <div className=" antialiased font-sans text-sm font-medium text-blue-gray-500">
                                                            {!averageCompressionBonus ? 0 : `${averageCompressionBonus.toString().split('.')[0]}`}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex items-center gap-4 py-1">
                                                    <div className="relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] after:h-4/6">

                                                        <i className="fa-solid fa-circle text-[#D1A4AC]"></i>
                                                    </div>
                                                    <div style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }} className="d-flex">
                                                        <div className="antialiased font-sans text-sm leading-normal text-[#000000]  font-medium">
                                                            Subscriptions Only Estimate
                                                        </div>
                                                        <div className=" antialiased font-sans text-sm font-medium text-blue-gray-500">
                                                            {!subscriptionsOnlyEstimate ? 0 : `${subscriptionsOnlyEstimate.toString().split('.')[0]}`}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-4 py-1">
                                                    <div className="relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] after:h-4/6">

                                                        <i className="fa-solid fa-circle text-[#D1A4AC]"></i>
                                                    </div>
                                                    <div style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }} className="d-flex">
                                                        <div className="antialiased font-sans text-sm leading-normal text-[#000000]  font-medium">
                                                            Points Gained Last month without subscriptions
                                                        </div>
                                                        <div className=" antialiased font-sans text-sm font-medium text-blue-gray-500">
                                                            {!pointsGainedLastMonthWithoutSubscriptions ? 0 : `${pointsGainedLastMonthWithoutSubscriptions.toString().split('.')[0]}`}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-4 py-1">
                                                    <div className="relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] after:h-4/6">

                                                        <i className="fa-solid fa-circle text-[#D1A4AC]"></i>
                                                    </div>
                                                    <div style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }} className="d-flex">
                                                        <div className="antialiased font-sans text-sm leading-normal text-[#000000]  font-medium">
                                                            Points Gained this month without subscriptions
                                                        </div>
                                                        <div className=" antialiased font-sans text-sm font-medium text-blue-gray-500">
                                                            {!pointsGainedThisMonthWithoutSubscriptions ? 0 : `${pointsGainedThisMonthWithoutSubscriptions.toString().split('.')[0]}`}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-4 py-1">
                                                    <div className="relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] after:h-4/6">

                                                        <i className="fa-solid fa-circle text-[#D1A4AC]"></i>
                                                    </div>
                                                    <div style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }} className="d-flex">
                                                        <div className="antialiased font-sans text-sm leading-normal text-[#000000]  font-medium">
                                                            Estimated Points coming from discretionary orders
                                                        </div>
                                                        <div className=" antialiased font-sans text-sm font-medium text-blue-gray-500">
                                                            {!estimatedPointsComingFromDiscretionaryOrders ? 0 : `${estimatedPointsComingFromDiscretionaryOrders.toString().split('.')[0]}`}

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center gap-4 py-1">
                                                    <div className="relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] after:h-4/6">

                                                        <i className="fa-solid fa-circle text-[#D1A4AC]"></i>
                                                    </div>
                                                    <div style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }} className="d-flex">
                                                        <div className="antialiased font-sans text-sm leading-normal text-[#000000]  font-medium">
                                                            Estimated Points coming from New VIPs
                                                        </div>
                                                        <div className=" antialiased font-sans text-sm font-medium text-blue-gray-500">
                                                            {!estimatedPointsComingFromNewVIPs ? 0 : `${estimatedPointsComingFromNewVIPs.toString().split('.')[0]}`}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div style={{ border: "1px solid #ffffff", background: "#ffffff" }} className="col-lg-6 p-0 px-2 pl-3">
                                <div className="row ">
                                    {/* <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className="col-lg-4 p-1 ">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED", height: "180px", display: "flex", flexDirection: "column", justifyContent: "center" }} className="">
                                            <h3 style={{ fontSize: "15px", fontWeight: "600" }} className="text-center">New Retails</h3>
                                            <p style={{ fontSize: "40px", color: "#D1A4AC", fontWeight: "600" }} className="text-center cursor-pointer">{!newRetails ? 0 : newRetails}</p>
                                            <i onClick={handleNewRetailsReportDownload} style={{ position: "absolute", top: "80%", right: "5%", transform: "tra", color: "#D1A4AC" }} className="fa-solid fa-download text-md cursor-pointer"></i>
                                        </div>
                                    </div> */}
{/* 
                                    <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className="col-lg-4 p-1">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED", height: "180px", display: "flex", flexDirection: "column", justifyContent: "center", position: "relative" }} className="">
                                            <h3 style={{ fontSize: "15px", fontWeight: "600" }} className="text-center">
                                                {selectedLevel === 'level1' ? "Level 1 VIPS" : "Level 2+ VIPS"}
                                            </h3>
                                            <p style={{ fontSize: "40px", color: "#D1A4AC", fontWeight: "600" }} className="text-center cursor-pointer">
                                                {vipsData || 0}
                                            </p>
                                            <i onClick={handleReportDownload} style={{ position: "absolute", top: "80%", right: "5%", transform: "tra", color: "#D1A4AC" }} className="fa-solid fa-download text-md cursor-pointer"></i>
                                            <FormControlLabel
                                                control={<Switch sx={{
                                                    '& .MuiSwitch-thumb': {
                                                        color: "#D1A4AC",
                                                    },
                                                    '& .MuiSwitch-track': {
                                                        backgroundColor: "#D1A4AC",

                                                    },
                                                    '& .MuiSwitch-root': {
                                                        backgroundColor: "#D1A4AC",
                                                    },

                                                }} checked={selectedLevel === 'level2'} onChange={handleToggle} />}
                                                style={{ position: "absolute", bottom: "0%", left: "5%" }}
                                            />

                                        </div>
                                    </div> */}

                                    {/* <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className="col-lg-4 p-1">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED", height: "180px", display: "flex", flexDirection: "column", justifyContent: "center" }} className="">
                                            <h3 style={{ fontSize: "15px", fontWeight: "600" }} className="text-center">New Brand Ambassadors</h3>
                                            <p style={{ fontSize: "40px", color: "#D1A4AC", fontWeight: "600" }} className="text-center cursor-pointer">{!newBrandAmbassdors ? 0 : newBrandAmbassdors}</p>
                                            <i onClick={handleForBrandAmbassadorsReportDownload} style={{ position: "absolute", top: "80%", right: "5%", transform: "tra", color: "#D1A4AC" }} className="fa-solid fa-download text-md cursor-pointer"></i>
                                        </div>
                                    </div> */}

                                    <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className=" col-lg-12 p-1">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED", height: "180px", display: "flex", flexDirection: "column", justifyContent: "center" }} className="py-3">
                                            <div className="flex w-[80%] m-auto justify-between">
                                            <h3 style={{ fontSize: "16px", fontWeight: "600" }} className="text-center ">New VIPS <span style={{fontSize:"13px",fontWeight: "400"}}>*Previous Month*</span></h3>
                                            <h3 style={{ fontSize: "16px", fontWeight: "600" }} className="text-center ">New VIPS <span style={{fontSize:"13px",fontWeight: "400"}}>*Current Month*</span></h3>
                                            </div>
                                            <ul className="flex w-[80%]  m-auto justify-between items-center">
                                            <ul className="flex flex-col justify-center items-center">
                                                    <li style={{ fontSize: "14px" }}>Counts</li>
                                                    <li style={{ fontSize: "25px", color: "#D1A4AC", fontWeight: "600" }} className="text-center">{!prevMonthVipsCounts ? 0 : `${prevMonthVipsCounts}`}</li>
                                                </ul>
                                                <ul className="flex flex-col justify-center items-center">
                                                    <li style={{ fontSize: "14px" }}>Points</li>
                                                    <li style={{ fontSize: "25px", color: "#D1A4AC", fontWeight: "600" }} className="text-center"> {!prevMonthVipsPoints ? 0 : `${prevMonthVipsPoints}`}</li>
                                                </ul>
                                                <ul className="flex flex-col justify-center items-center">
                                                    <li style={{ fontSize: "14px" }}>Counts</li>
                                                    <li style={{ fontSize: "25px", color: "#D1A4AC", fontWeight: "600" }} className="text-center">{!curMonthVipsCounts ? 0 : `${curMonthVipsCounts}`}</li>
                                                </ul>
                                                <ul className="flex flex-col justify-center items-center">
                                                    <li style={{ fontSize: "14px" }}>Points</li>
                                                    <li style={{ fontSize: "25px", color: "#D1A4AC", fontWeight: "600" }} className="text-center">  {!curMonthVipsPoints ? 0 : `${curMonthVipsPoints}`}</li>
                                                </ul>
                                            </ul>
                                            {/* <p style={{ fontSize: "40px", color: "#D1A4AC", fontWeight: "600" }} className="text-center cursor-pointer">{monthlyOrdersReport?.length} ,{previousMonthOrders}</p> */}
                                        </div>
                                    </div>
                                    <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className=" col-lg-6 p-1">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED", height: "180px", display: "flex", flexDirection: "column", justifyContent: "center" }} className="py-3">
                                            <h3 style={{ fontSize: "15px", fontWeight: "600" }} className="text-center">Number of Orders</h3>
                                            <ul className="flex w-[70%]  m-auto justify-between items-center">
                                                <ul>
                                                    <li style={{ fontSize: "14px" }}>Last Month</li>
                                                    <li style={{ fontSize: "25px", color: "#D1A4AC", fontWeight: "600" }} className="text-center">{ordersPreviousMonthsCount?ordersPreviousMonthsCount:0}</li>
                                                </ul>
                                                <ul>
                                                    <li style={{ fontSize: "14px" }}>This Month</li>
                                                    <li style={{ fontSize: "25px", color: "#D1A4AC", fontWeight: "600" }} className="text-center"> {monthlyOrdersReport?.length ? monthlyOrdersReport?.length : 0 }</li>
                                                </ul>
                                            </ul>
                                            {/* <p style={{ fontSize: "40px", color: "#D1A4AC", fontWeight: "600" }} className="text-center cursor-pointer">{monthlyOrdersReport?.length} ,{previousMonthOrders}</p> */}
                                            <i onClick={handleMonthlyOrderReportDownload} style={{ position: "absolute", top: "80%", right: "5%", transform: "tra", color: "#D1A4AC" }} className="fa-solid fa-download text-md cursor-pointer"></i>
                                        </div>
                                    </div>

                                    <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className=" col-lg-6 p-1">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED", height: "180px", display: "flex", flexDirection: "column", justifyContent: "center" }} className="py-3">
                                            <h3 style={{ fontSize: "15px", fontWeight: "600" }} className="text-center">Overall Team PV</h3>
                                            <ul className="flex w-[70%]  m-auto justify-between items-center">
                                                <ul>
                                                    <li style={{ fontSize: "14px" }}>Last Month</li>
                                                    <li style={{ fontSize: "20px", color: "#D1A4AC", fontWeight: "600" }} className="text-center">{totalPrevMonthPersonalVolume?totalPrevMonthPersonalVolume :0}</li>
                                                </ul>
                                                <ul>
                                                    <li style={{ fontSize: "14px" }}>This Month</li>
                                                    <li style={{ fontSize: "20px", color: "#D1A4AC", fontWeight: "600" }} className="text-center"> {totalCurMonthPersonalVolume?totalCurMonthPersonalVolume:0}</li>
                                                </ul>
                                            </ul>
                                            {/* <p style={{ fontSize: "40px", color: "#D1A4AC", fontWeight: "600" }} className="text-center cursor-pointer">{monthlyOrdersReport?.length} ,{previousMonthOrders}</p> */}
                                        </div>
                                    </div>
                                    <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className="col-lg-6 p-1 pt-2 mb-2">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED", height: "180px", display: "flex", flexDirection: "column", justifyContent: "center" }} className="">
                                            <h3 style={{ fontSize: "15px", fontWeight: "600" }} className="text-center">CC Declines </h3>
                                            <p style={{ fontSize: "40px", color: "#D1A4AC", fontWeight: "600" }} className="text-center cursor-pointer"> {!creditCardDeclineRate ? 0 : creditCardDeclineRate.toFixed(2)}%</p>
                                            <i onClick={handleCCDeclineReportDownload} style={{ position: "absolute", top: "80%", right: "5%", transform: "tra", color: "#D1A4AC" }} className="fa-solid fa-download text-md cursor-pointer"></i>
                                        </div>
                                    </div>


                                    {/* <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className="col-lg-4 p-1 pt-2">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED", height: "180px", display: "flex", flexDirection: "column", justifyContent: "center" }} className="">
                                            <h3 style={{ fontSize: "15px", fontWeight: "600" }} className="text-center">New Ranks</h3>
                                            <p style={{ fontSize: "40px", color: "#D1A4AC", fontWeight: "600" }} className="text-center cursor-pointer">{!newRanks ? 0 : newRanks}</p>
                                            <i onClick={handleForNewRanksReportDownload} style={{ position: "absolute", top: "80%", right: "5%", transform: "tra", color: "#D1A4AC" }} className="fa-solid fa-download text-md cursor-pointer"></i>
                                        </div>
                                    </div>
                                    <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className="col-lg-4 p-1 pt-2">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED", height: "180px", display: "flex", flexDirection: "column", justifyContent: "center" }} className="">
                                            <h3 style={{ fontSize: "15px", fontWeight: "600" }} className="text-center">Current PV</h3>
                                            <p style={{ fontSize: "40px", color: "#D1A4AC", fontWeight: "600" }} className="text-center cursor-pointer">{!currentPv ? 0 : currentPv}</p>
                                            <i onClick={handlePvReportDownload} style={{ position: "absolute", top: "80%", right: "5%", transform: "tra", color: "#D1A4AC" }} className="fa-solid fa-download text-md cursor-pointer"></i>
                                        </div>
                                    </div>

                                    
                                     */}

                                    {/* <div style={{ minHeight: "180px", maxHeight: "180px", overflow: "hidden" }} className="col-lg-3 p-1 pt-2">
                                        <div style={{ border: "1px solid #ffE9ED", borderRadius: "7px", background: "#ffE9ED" }} className="">
                                            <CircleChart2 />
                                        </div>
                                    </div> */}

                                </div>
                            </div>

                            <div style={{ border: "1px solid #ffffff", background: "#ffffff" }} className="col-lg-6 py-1  px-0 pr-2">
                                <div className="row col-lg-12 m-0 pr-0">
                                    <div className="col-lg-6 relative flex flex-col bg-clip-border rounded-xl bg-[#ffE9ED] text-gray-700 shadow-md mb-2 ">
                                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className="relative bg-clip-border rounded-xl overflow-hidden bg-transparent text-gray-700 shadow-none m-0 px-6 pt-6 pb-0">
                                            <h6 className="block antialiased tracking-normal font-sans text-lg font-semibold leading-relaxed text-blue-gray-900 ">
                                                Leadersboard
                                            </h6>


                                            <i onClick={handleDownloadCsvForLeadersboard} className="fa-solid fa-download text-md cursor-pointer"></i>
                                        </div>
                                        <div className="px-6 py-2 pt-0">
                                            {visibleLeadersboard.map((notification, index) => (
                                                <div key={index} className="flex items-center gap-4 py-1">
                                                    <div className="relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] after:h-4/6">

                                                        <i className="fas fa-user text-[#D1A4AC]"></i>
                                                    </div>
                                                    <div style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }} className="d-flex">
                                                        <div className="antialiased font-sans text-sm leading-normal text-[#000000]  font-medium">
                                                            {notification.SponsorName}
                                                        </div>
                                                        <div className=" antialiased font-sans text-sm font-medium text-blue-gray-500">
                                                            {notification.TopNewVip}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="flex justify-center">
                                                <button onClick={handleShowMoreClick} className="text-[#D1A4AC] text-sm font-semibold mt-2">
                                                    {showMore ? "Show Less" : "Show More"}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 paddingX2">
                                        <div className="col-lg-12 relative flex flex-col bg-clip-border rounded-xl bg-[#ffE9ED] text-gray-700 shadow-md mb-2">
                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }} className="relative bg-clip-border rounded-xl overflow-hidden bg-transparent text-gray-700 shadow-none m-0 px-6 pt-6 pb-0">
                                                <h6 className="block antialiased tracking-normal font-sans text-lg font-semibold leading-relaxed text-blue-gray-900 ">
                                                    Points Per Leg
                                                </h6>
                                                <i onClick={handleDownloadCsvForPointsPerLeg} className="fa-solid fa-download text-md cursor-pointer"></i>
                                            </div>
                                            <div className="px-6 py-2 pt-0">
                                                {legs && legs.length > 0 && visibleLeg.map((curLeg, id) => (
                                                    <div key={id} className="flex items-center gap-4 py-1">
                                                        <div className="relative p-1 after:absolute after:-bottom-6 after:left-2/4 after:w-0.5 after:-translate-x-2/4 after:bg-blue-gray-50 after:content-[''] after:h-4/6">

                                                            <i className="fa-solid fa-ranking-star text-[#D1A4AC]"></i>
                                                        </div>
                                                        <div style={{ alignItems: "center", justifyContent: "space-between", width: "100%" }} className="d-flex">
                                                            <div className="antialiased font-sans text-sm leading-normal text-[#000000]  font-medium">
                                                                {curLeg.LegName}
                                                            </div>
                                                            <div className=" antialiased font-sans text-sm font-medium text-blue-gray-500">
                                                                {curLeg.PlexusPointsInOrganization}
                                                            </div>
                                                        </div>
                                                    </div>

                                                ))}
                                                <div className="flex justify-center">
                                                    <button onClick={handleShowMoreClick2} className="text-[#D1A4AC] text-sm font-semibold mt-2">
                                                        {showMore2 ? "Show Less" : "Show More"}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>



                    </div>
                </div>
            </section >
        </div >
    );
};

export default Dashboard;
