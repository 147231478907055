import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import store from './Store';
// import { ThemeProvider } from "@material-tailwind/react";

const root = ReactDOM.createRoot(document.getElementById('root'));
serviceWorkerRegistration.register();
root.render(
  <React.StrictMode>
  <Provider store={store}>
      <App />
  </Provider>
</React.StrictMode>
);



