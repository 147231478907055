import Papa from "papaparse"

//by Pv report
export const generateCSVForPvReport = (data) => {
  const csv = Papa.unparse({
    fields: ['Customer ID', 'First Name', 'Last Name', 'Pv', 'Email', 'Phone'],
    data: data.map((row) => [row['customerID'], row['firstName'], row['lastName'], row['PV'], row['email'], row['phone']]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'pvReport.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const generateCSVForRetails = (data) => {

  // Generate the second CSV file with specific fields for Level 2 data
  const csv = Papa.unparse({
    fields: ['Customer ID', 'First Name', 'Last Name', 'Account Created', 'Status', 'PV', 'Email', 'Phone'],
    data: data.map((row) => [
      row['customerID'],
      row['firstName'],
      row['lastName'],
      row['PV'],
      row['email'],
      row['phone'],
    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'NewRetailsReport.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};


//by Genealogy report


export const generateCSVForLvl2 = (data) => {
  // Generate the second CSV file with specific fields for Level 2 data
  const csv = Papa.unparse({
    fields: ['Level', 'Name', 'ID', 'Phone', 'Email', 'SponsorName', 'CustomerType', 'PV', 'WelcomePack', 'JoinDate', 'NextSubscription', 'SubscriptionPV'],
    data: data.map((row) => [
      row['Level'],
      row['Name'],
      row['ID'],
      row['Phone'],
      row['Email'],
      row['SponsorName'],
      row['CustomerType'],
      row['PV'],
      row['WelcomePack'],
      row['JoinDate'],
      row['NextSubscription'],
      row['SubscriptionPV'],
    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'NewVIPCustomerslevel2.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const generateCSVForLvl1 = (data) => {
  // Generate the second CSV file with specific fields for Level 2 data
  const csv = Papa.unparse({
    fields: ['Level', 'Name', 'ID', 'Phone', 'Email', 'SponsorName', 'CustomerType', 'PV', 'WelcomePack', 'JoinDate', 'NextSubscription', 'SubscriptionPV'],
    data: data.map((row) => [
      row['Level'],
      row['Name'],
      row['ID'],
      row['Phone'],
      row['Email'],
      row['SponsorName'],
      row['CustomerType'],
      row['PV'],
      row['WelcomePack'],
      row['JoinDate'],
      row['NextSubscription'],
      row['SubscriptionPV'],
    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'NewVIPCustomerslevel1.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const generateCSVForCurrentPoints = (data) => {
  // Generate the first CSV file with all fields
  const csv = Papa.unparse({
    fields: ['CustomerID', 'FirstName', 'LastName', 'Phone', 'Email','SponsorID', 'SponsorFirstName', 'SponsorLastName', 'CustomerType', 'Level',"PlexusPointsInOrganization",  'PersonalVolume', 'NextAutoOrder', 'OrderIDList'],
    data: data.map((row) => [
      row['CustomerID'] || '',
      row['FirstName'] || '',
      row['LastName'] || '',
      row['Phone'] || '',
      row['Email'] || '',
      row['SponsorID'] || '',
      row['SponsorFirstName'] || '',
      row['SponsorLastName'] || '',
      row['CustomerType'] || '', // Add missing fields as needed
      row['Level'] || '',
      row['PlexusPointsInOrganization'] || '',
      row['PersonalVolume'] || '',
      row['NextAutoOrder'] || '',
      row['OrderIDList'] || '',
    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'currentPointsReport.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const generateCSVForBrandAmbassadors = (data) => {


  // Generate the CSV file for new brand ambassadors
  const csv = Papa.unparse({
    fields: ['CustomerID', 'FirstName', 'LastName', 'Phone', 'Email', 'SponsorID', 'SponsorFirstName', 'SponsorLastName', 'CustomerType', 'Level', 'PersonalVolume', 'NextAutoOrder', 'OrderIDList'],
    data: data.map((row) => [
      row['CustomerID'] || '',
      row['FirstName'] || '',
      row['LastName'] || '',
      row['Phone'] || '',
      row['Email'] || '',
      row['SponsorID'] || '',
      row['SponsorFirstName'] || '',
      row['SponsorLastName'] || '',
      row['CustomerType'] || '', // Add missing fields as needed
      row['Level'] || '',
      row['PersonalVolume'] || '',
      row['NextAutoOrder'] || '',
      row['OrderIDList'] || '',
    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'NewBrandAmbassadors.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export const generateCSVForMonthlyOrdersReport = (data) => {


  // Generate the CSV file for new brand ambassadors
  const csv = Papa.unparse({
    fields: ['CustomerID', 'FirstName', 'LastName', 'Phone', 'Email', 'SponsorID', 'SponsorFirstName', 'SponsorLastName', 'CustomerType', 'Level', 'PersonalVolume', 'NextAutoOrder', 'OrderIDList'],
    data: data.map((row) => [
      row['CustomerID'] || '',
      row['FirstName'] || '',
      row['LastName'] || '',
      row['Phone'] || '',
      row['Email'] || '',
      row['SponsorID'] || '',
      row['SponsorFirstName'] || '',
      row['SponsorLastName'] || '',
      row['CustomerType'] || '', // Add missing fields as needed
      row['Level'] || '',
      row['PersonalVolume'] || '',
      row['NextAutoOrder'] || '',
      row['OrderIDList'] || '',
    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'NumberOfOrders.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export const generateCSVForlvl2PlusVips = (data) => {


  // Generate the CSV file for new brand ambassadors
  const csv = Papa.unparse({
    fields: ['CustomerID', 'FirstName', 'LastName', 'Phone', 'Email','CreatedDate','JoinDate', 'SponsorID', 'SponsorFirstName', 'SponsorLastName', 'CustomerType', 'Level', 'PersonalVolume', 'NextAutoOrder', 'OrderIDList'],
    data: data.map((row) => [
      row['CustomerID'] || '',
      row['FirstName'] || '',
      row['LastName'] || '',
      row['Phone'] || '',
      row['Email'] || '',
      row['CreatedDate'] || '',
      row['JoinDate'] || '',
      row['SponsorID'] || '',
      row['SponsorFirstName'] || '',
      row['SponsorLastName'] || '',
      row['CustomerType'] || '', // Add missing fields as needed
      row['Level'] || '',
      row['PersonalVolume'] || '',
      row['NextAutoOrder'] || '',
      row['OrderIDList'] || '',
    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'VipsLvl2Plus.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export const generateCSVForlvl1Vips = (data) => {


  // Generate the CSV file for new brand ambassadors
  const csv = Papa.unparse({
    fields: ['CustomerID', 'FirstName', 'LastName', 'Phone', 'Email','CreatedDate','JoinDate', 'SponsorID', 'SponsorFirstName', 'SponsorLastName', 'CustomerType', 'Level', 'PersonalVolume', 'NextAutoOrder', 'OrderIDList'],
    data: data.map((row) => [
      row['CustomerID'] || '',
      row['FirstName'] || '',
      row['LastName'] || '',
      row['Phone'] || '',
      row['Email'] || '',
      row['CreatedDate'] || '',
      row['JoinDate'] || '',
      row['SponsorID'] || '',
      row['SponsorFirstName'] || '',
      row['SponsorLastName'] || '',
      row['CustomerType'] || '', // Add missing fields as needed
      row['Level'] || '',
      row['PersonalVolume'] || '',
      row['NextAutoOrder'] || '',
      row['OrderIDList'] || '',
    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'VipsLvl1.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const generateCSVForTopLeaderWithNewBrandAmbassadors = (data) => {
  // Generate the CSV file for new brand ambassadors
  const csv = Papa.unparse({
    fields: ['Sponsor Name', 'New Ambassadors'],
    data: data.map((row) => [
      row['SponsorName'],
      row['newAmbassadors'],
     
    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'TopLeadersWithNewAmbassadors.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export const generateCSVForRecruitersReport = (data) => {
  // Generate the CSV file for new brand ambassadors
  const csv = Papa.unparse({
    fields: ['Sponsor Name', 'Top New Vips'],
    data: data.map((row) => [
      row['SponsorName'],
      row['TopNewVip'],
     
    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'TopRecruitersSheet.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export const generateCSVForPointsPerLeg = (data) => {
  // Generate the CSV file for new brand ambassadors
  const csv = Papa.unparse({
    fields: ['Leg Name', 'PlexusPointsInOrganization'],
    data: data.map((row) => [
      row['LegName'],
      row['PlexusPointsInOrganization'],
     
    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'PointsPerLegReport.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};


//By rank advancement

export const generateCSVForNewRanksReport = (data) => {
  const csv = Papa.unparse({
    fields: ['CustomerID', 'FirstName', 'LastName', 'Phone', 'Email', 'SponsorID', 'SponsorFirstName', 'SponsorLastName', 'Level', 'Projected Rank'],
    data: data.map((row) => [
      row['CustomerID'] || '',
      row['FirstName'] || '',
      row['LastName'] || '',
      row['Phone'] || '',
      row['Email'] || '',
      row['SponsorID'] || '',
      row['SponsorFirstName'] || '',
      row['SponsorLastName'] || '',
      row['Level'] || '',
      row['ProjectedRank'] || '',

    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'NewRanksReport.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};


//by Points and rankup file
export const generateCSVForCreditCardDeclineReport = (data) => {
  const csv = Papa.unparse({
    fields: ['CustomerID', 'FirstName', 'LastName', 'Phone', 'Email', 'SponsorFirstName', 'SponsorLastName', 'CustomerType', 'Level', 'PersonalVolume', 'NextAutoOrder', 'OrderIDList'],
    data: data.map((row) => [row.CustomerID, row.FirstName, row.LastName, row.Phone, row.Email, row.SponsorFirstName, row.SponsorLastName, row.CustomerType, row.Level, row.PersonalVolume, row.NextAutoOrder, row.OrderIDList]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'CreditCardDeclineReport.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export const generateCSVForOrdersMonthlyReport = (data) => {
  const csv = Papa.unparse({
    fields: ['CustomerID', 'FirstName', 'LastName', 'Phone', 'Email', 'SponsorID', 'SponsorFirstName', 'SponsorLastName', 'CustomerType', 'Level', 'PersonalVolume', 'NextAutoOrder', 'OrderIDList'],
    data: data.map((row) => [
      row['CustomerID'] || '',
      row['FirstName'] || '',
      row['LastName'] || '',
      row['Phone'] || '',
      row['Email'] || '',
      row['SponsorID'] || '',
      row['SponsorFirstName'] || '',
      row['SponsorLastName'] || '',
      row['CustomerType'] || '', // Add missing fields as needed
      row['Level'] || '',
      row['PersonalVolume'] || '',
      row['NextAutoOrder'] || '',
      row['OrderIDList'] || '',
    ]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'OrdersMonthlyReport.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
export const generateCSVForLegs = (data) => {
  const csv = Papa.unparse({
    fields: ['LegName', 'PlexusPointsInOrganization'],
    data: data.map((row) => [row.LegName, row.PlexusPointsInOrganization]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'PointsPerLeg.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};




export const generateCSVForTopNewVip = (data) => {
  const csv = Papa.unparse({
    fields: ['SponsorName', 'TopNewVip'],
    data: data.map((row) => [row.SponsorName, row.TopNewVip]),
  });

  // Create a Blob from the CSV data and create a download link for the user
  const blob = new Blob([csv], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = 'TopNewVipReport.csv';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};