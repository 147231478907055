import React from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from '../Component/Navbar'
import Sidebar from '../Component/Sidebar'
import Footer from '../Component/Footer'
import Dashboard from "../Component/Dashboard"

const AdminLayout = ({userData}) => {
  return (
    <>
      <div className="hold-transition sidebar-mini layout-fixed">
        <div className="wrapper ">
          <Navbar userData={userData} />
          <Sidebar userData ={userData}/>
          <Outlet />
          <Footer />
        </div>
      </div>

      {/* <Header/> */}

    </>

    // <Sidebar Outlet={Outlet}/>
  )
}

export default AdminLayout