import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"

export const uploadPvReport = createAsyncThunk("upload/pvReport", async ( formData , { rejectWithValue }) => {
    let url = `https://backend.theanalyticaladvantage.com/api/v1/upload/pvReport `
    const config = {
        headers: {
            // "Content-Type": "application/json"         //bina config ke cookie nhi set honi dhyan rakhna
        },
        withCredentials: true
    }
    try {
        const response = await axios.post(url, formData,config ); // replace with your API endpoint and data
        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.response.data.error)
    }
})
export const getPvReportData = createAsyncThunk("get/pvReportData", async ( formData , { rejectWithValue }) => {
    let url = `https://backend.theanalyticaladvantage.com/api/v1/get/pvReportData `
    const config = {
        headers: {
            // "Content-Type": "application/json"         //bina config ke cookie nhi set honi dhyan rakhna
        },
        withCredentials: true
    }
    try {
        const response = await axios.post(url,{},config ); // replace with your API endpoint and data
        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.response.data.error)
    }
})




export const uploadGenealogyReport = createAsyncThunk("upload/genealogyReport", async ( formData , { rejectWithValue }) => {
    let url = `https://backend.theanalyticaladvantage.com/api/v1/upload/genealogyReport `
    const config = {
        headers: {
            // "Content-Type": "application/json"         //bina config ke cookie nhi set honi dhyan rakhna
        },
        withCredentials: true
    }
    try {
        const response = await axios.post(url, formData,config); // replace with your API endpoint and data
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.error)
    }
})

export const getGenealogyReportData = createAsyncThunk("get/genealogyReportData", async ( formData , { rejectWithValue }) => {
    const config = {
        headers: {
            // "Content-Type": "application/json"         //bina config ke cookie nhi set honi dhyan rakhna
        },
        withCredentials: true
    }
    let url = `https://backend.theanalyticaladvantage.com/api/v1/get/genealogyReportData `
   
    try {
        const response = await axios.post(url,{},config ); // replace with your API endpoint and data
        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.response.data.error)
    }
})

export const uploadRankAdvancementReport = createAsyncThunk("upload/rankAdvancementReport", async ( formData , { rejectWithValue }) => {
    let url = `https://backend.theanalyticaladvantage.com/api/v1/upload/rankAdvancementReport `
    const config = {
        headers: {
            // "Content-Type": "application/json"         //bina config ke cookie nhi set honi dhyan rakhna
        },
        withCredentials: true
    }
    try {
        const response = await axios.post(url, formData,config); // replace with your API endpoint and data
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.error)
    }
})
export const getRankAdvancementReportData = createAsyncThunk("get/RankAdvancementReportData", async ( formData , { rejectWithValue }) => {
    let url = `https://backend.theanalyticaladvantage.com/api/v1/get/rankAdvancementReportData `
    const config = {
        headers: {
            // "Content-Type": "application/json"         //bina config ke cookie nhi set honi dhyan rakhna
        },
        withCredentials: true
    }
    try {
        const response = await axios.post(url,{},config ); // replace with your API endpoint and data
        return response.data;
    }
    catch (error) {
        return rejectWithValue(error.response.data.error)
    }
})

export const uploadPointsAndRankupReport = createAsyncThunk("upload/pointsAndRankupReport", async ( {formData} , { rejectWithValue }) => {
    // console.log(selectedMonth)
    let url = `https://backend.theanalyticaladvantage.com/api/v1/upload/pointsAndRankupReport `
    const config = {
        headers: {
            // "Content-Type": "application/json"         //bina config ke cookie nhi set honi dhyan rakhna
        },
        withCredentials: true
    }
    try {
        const response = await axios.post(url, formData,config); // replace with your API endpoint and data
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.error)
    }
})
export const getPointsAndRankupReportData = createAsyncThunk("get/pointsAndRankupReportData", async ( formData , { rejectWithValue }) => {
    let url = `https://backend.theanalyticaladvantage.com/api/v1/get/pointsAndRankupReportData `
    const config = {
        headers: {
            // "Content-Type": "application/json"         //bina config ke cookie nhi set honi dhyan rakhna
        },
        withCredentials: true
    }
    try {
        const response = await axios.post(url,{},config); // replace with your API endpoint and data
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.error)
    }
})
export const getOrderReportFullYear = createAsyncThunk("get/orderReportFullYear", async ( formData , { rejectWithValue }) => {
    let url = `https://backend.theanalyticaladvantage.com/api/v1/get/orderReportFullYear `
    const config = {
        headers: {
            // "Content-Type": "application/json"         //bina config ke cookie nhi set honi dhyan rakhna
        },
        withCredentials: true
    }
    try {
        const response = await axios.post(url,{},config); // replace with your API endpoint and data
        return response.data;
    } catch (error) {
        return rejectWithValue(error.response.data.error)
    }
})



export const pdfSlice = createSlice({
    name: "pdf",
    initialState: {
        isLoading: false,
        isError: false,
        isSuccess: false,
        isCreated: false,
        allUsers: [],
        PvReportData:null,
        GenealogyReportData:null,
        RankAdvancementReportData:null,
        PointsAndRankupReportData:null,
        YearlyReportData: [],

        isPvSuccess: false,
        isPvError: false,
        isPvLoading: false,

        isPointsSuccess: false,
        isPointsError: false,
        isPointsLoading: false,

        isRankSuccess: false,
        isRankError: false,
        isRankLoading: false,
        

        
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadPvReport.pending, (state) => {
                state.isPvSuccess = false
                state.isPvLoading = true
                state.isPvError = false
            })
            .addCase(uploadPvReport.fulfilled, (state, action) => {
                state.isPvSuccess = true
                state.isPvError=false
                state.isPvLoading = false
            })
            .addCase(uploadPvReport.rejected, (state, action) => {
                state.isPvSuccess = false
                state.isPvError= true
                state.isPvLoading = false
               
            })
            .addCase(uploadGenealogyReport.pending, (state) => {
                state.isSuccess = false
                state.isLoading = true
                state.isError = false
            })
            .addCase(uploadGenealogyReport.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isError=false
                state.isLoading = false
            })
            .addCase(uploadGenealogyReport.rejected, (state, action) => {
                state.isSuccess = false
                state.isError= true
                state.isLoading = false
               
            })
            .addCase(uploadRankAdvancementReport.pending, (state) => {
                state.isRankSuccess = false
                state.isRankLoading = true
                state.isRankError = false
            })
            .addCase(uploadRankAdvancementReport.fulfilled, (state, action) => {
                state.isRankSuccess = true
                state.isRankError=false
                state.isRankLoading = false
            })
            .addCase(uploadRankAdvancementReport.rejected, (state, action) => {
                state.isRankSuccess = false
                state.isRankError= true
                state.isRankLoading = false
              
               
            })
            .addCase(uploadPointsAndRankupReport.pending, (state) => {
                state.isPointsSuccess = false
                state.isPointsLoading = true
                state.isPointsError = false
            })
            .addCase(uploadPointsAndRankupReport.fulfilled, (state, action) => {
                state.isPointsSuccess = true
                state.isPointsError=false
                state.isPointsLoading = false
                
            })
            .addCase(uploadPointsAndRankupReport.rejected, (state, action) => {
                state.isPointsSuccess = false
                state.isPointsError= true
                state.isPointsLoading = false
                
               
            })
            .addCase(getPvReportData.pending, (state) => {
                state.isSuccess = false
                state.isLoading = true
                state.isError = false
            })
            .addCase(getPvReportData.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isError=false
                state.isLoading = false
                state.PvReportData = action.payload
            })
            .addCase(getPvReportData.rejected, (state, action) => {
                state.isSuccess = false
                state.isError= true
                state.isLoading = false
                state.PvReportData = null
            })
            .addCase(getRankAdvancementReportData.pending, (state) => {
                state.isSuccess = false
                state.isLoading = true
                state.isError = false
            })
            .addCase(getRankAdvancementReportData.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isError=false
                state.isLoading = false
                state.RankAdvancementReportData = action.payload

            })
            .addCase(getRankAdvancementReportData.rejected, (state, action) => {
                state.isSuccess = false
                state.isError= true
                state.isLoading = false
                state.RankAdvancementReportData = null


            })
            .addCase(getGenealogyReportData.pending, (state) => {
                state.isSuccess = false
                state.isLoading = true
                state.isError = false
            })
            .addCase(getGenealogyReportData.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isError=false
                state.isLoading = false
                state.GenealogyReportData = action.payload
            })
            .addCase(getGenealogyReportData.rejected, (state, action) => {
                state.isSuccess = false
                state.isError= true
                state.isLoading = false
                state.GenealogyReportData = null
               
            })
            .addCase(getPointsAndRankupReportData.pending, (state) => {
                state.isSuccess = false
                state.isLoading = true
                state.isError = false
            })
            .addCase(getPointsAndRankupReportData.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isError=false
                state.isLoading = false
                state.PointsAndRankupReportData = action.payload
            })
            .addCase(getPointsAndRankupReportData.rejected, (state, action) => {
                state.isSuccess = false
                state.isError= true
                state.isLoading = false
                state.PointsAndRankupReportData = null
               
            })
            .addCase(getOrderReportFullYear.pending, (state) => {
                state.isSuccess = false
                state.isLoading = true
                state.isError = false
            })
            .addCase(getOrderReportFullYear.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isError=false
                state.isLoading = false
                state.YearlyReportData = action.payload
            })
            .addCase(getOrderReportFullYear.rejected, (state, action) => {
                state.isSuccess = false
                state.isError= true
                state.isLoading = false
                state.YearlyReportData = null
               
            })
           



    }
})


export default pdfSlice.reducer

