import React from 'react'
import { useNavigate } from 'react-router-dom'
import MembersTable from './MembersTable'

const Users = ({ userData }) => {
  const navigate = useNavigate()
  return (
    <div className="content-wrapper">
      <section className="content">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>All Users</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <p className="text-[#D1A4AC] cursor-pointer" onClick={() => navigate(`/${userData?.role}/dashboard`)}>Home</p>
                  </li>
                  <li className="breadcrumb-item active">Users</li>
                </ol>
              </div>
            </div>
          </div>
          <div className="w-full px-0 md:px-6 py-5">
           
            <MembersTable userData={userData}/>
          </div>
        </section>

      </section>
    </div>
  )
}

export default Users