import {configureStore} from "@reduxjs/toolkit"
import pdfSlice  from "./slices/PdfFilesSlice"
import userSlice from "./slices/userSlice"



const store = configureStore({
    reducer: {
        userCustom: userSlice,
        customPdf : pdfSlice,
        
    }
})

export default store