import React from "react";

const Footer = () => {
  return (
    <footer className="main-footer">
      <div className="text-center">
        This The Analytical Advantage Dashboard will accurately project data for you only. If other files are uploaded, they will not project the correct data.
      </div>
    </footer>
  );
};

export default Footer;
