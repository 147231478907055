import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uploadRankAdvancementReport } from '../slices/PdfFilesSlice';

const RankAdvancement = ({userData}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const dispatch = useDispatch();

    const [buttonText, setButtonText] = useState('Submit'); // Initial button text
    const navigate = useNavigate()

    const {isRankLoading,isRankError,isRankSuccess} = useSelector((state) => state.customPdf)

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            dispatch(uploadRankAdvancementReport( formData ))
        }
    };


    useEffect(() => {
        if (isRankLoading) {
            setButtonText('Uploading...');
        } else if (isRankSuccess) {
            setButtonText('Updated'); 
        } 
         else if (isRankError) {
            setButtonText('Failed'); 
        } 
         else{setButtonText('Submit'); }
        
    }, [isRankLoading, isRankError,isRankSuccess]);
    return (
        <div className="content-wrapper">
            <section className="content">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Rank Advancement Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <p className='cursor-pointer text-[#D1A4AC]' onClick={() => navigate(`/${userData?.role}/dashboard`)}>Home</p>
                                    </li>
                                    <li className="breadcrumb-item active">Rank Advancement Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                <div className="container full-width welcome-box-item-container">
                    <div className="row">
                        <div className="container">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Rank Advancement Report</h3>
                                        </div>
                                        <span id="message" style={{ marginTop: "5px" }}>
                                            {/* {message} */}
                                        </span>
                                        <form
                                            id="customerUpload"
                                            name="customerUpload"
                                            method="post"
                                            encType="multipart/form-data"
                                        onSubmit={handleSubmit}
                                        >
                                            <input type="hidden" name="_token" value="TnreQCyQdobinyNi25349otgAWYs1BD7XWccySHI" />
                                            <div className="card-body">

                                                <div className="form-group">
                                                    <label htmlFor="exampleInputFile">File input</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                className="custom-file-input"
                                                                id="exampleInputFile"
                                                                required
                                                            onChange={handleFileChange}
                                                            />
                                                            <label className="custom-file-label" htmlFor="exampleInputFile">
                                                                {selectedFile ? selectedFile.name : "Choose file"}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">
                                                <div className="progress" style={{ display: "none" }}>
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <button type="submit" name="import" id="import" className="btn btn-primary mt-3">
                                                  {buttonText}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* Continue rendering the other sections... */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default RankAdvancement