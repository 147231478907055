import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { uploadPointsAndRankupReport } from '../slices/PdfFilesSlice';

const PointsAndRankup = ({userData}) => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const dispatch = useDispatch();

    const navigate = useNavigate()
    const [buttonText, setButtonText] = useState('Submit'); // Initial button text


    const { isPointsLoading, isPointsError, isPointsSuccess } = useSelector((state) => state.customPdf)

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('selectedMonth', selectedMonth);

            dispatch(uploadPointsAndRankupReport({ formData }))
        }
    };


    useEffect(() => {
        if (isPointsLoading) {
            setButtonText('Uploading...');
        } else if (isPointsSuccess) {
            setButtonText('Updated'); 
        } 
         else if (isPointsError) {
            setButtonText('Failed'); 
        } 
         else{setButtonText('Submit'); }
        
    }, [isPointsLoading, isPointsError, isPointsSuccess]);


    return (
        <div className="content-wrapper">
            <section className="content">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Points&Rankup Report</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <p className='cursor-pointer text-[#D1A4AC]' onClick={() => navigate(`/${userData?.role}/dashboard`)}>Home</p>
                                    </li>
                                    <li className="breadcrumb-item active">Points & Rankup Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                <div className="container full-width welcome-box-item-container">
                    <div className="row">
                        <div className="container">
                            <div className="container-fluid">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Points & Rankup Report</h3>
                                        </div>
                                        <span id="message" style={{ marginTop: "5px" }}>
                                            {/* {message} */}
                                        </span>

                                        <form
                                            id="customerUpload"
                                            name="customerUpload"
                                            method="post"
                                            encType="multipart/form-data"
                                            onSubmit={handleSubmit}
                                        >

                                            <input type="hidden" name="_token" value="TnreQCyQdobinyNi25349otgAWYs1BD7XWccySHI" />
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="form-group col-sm-12">
                                                        <label className="form-label select-label name-lable-txt">Months</label>
                                                        <select className="form-control select name-select" name="months" id="months" value={selectedMonth} onChange={(event) => setSelectedMonth(event.target.value)}>
                                                            <option value="1">January</option>
                                                            <option value="2">February</option>
                                                            <option value="3">March</option>
                                                            <option value="4">April</option>
                                                            <option value="5">May</option>
                                                            <option value="6">June</option>
                                                            <option value="7">July</option>
                                                            <option value="8">August</option>
                                                            <option value="9">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </select>
                                                    </div>

                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInputFile">File input</label>
                                                    <div className="input-group">
                                                        <div className="custom-file">
                                                            <input
                                                                type="file"
                                                                name="file"
                                                                className="custom-file-input"
                                                                id="exampleInputFile"
                                                                required
                                                                onChange={handleFileChange}
                                                            />
                                                            <label className="custom-file-label" htmlFor="exampleInputFile">
                                                                {selectedFile ? selectedFile.name : "Choose file"}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card-footer">
                                                <div className="progress" style={{ display: "none" }}>
                                                    <div className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <button type="submit" name="import" id="import" className="btn btn-primary mt-3">
                                                    {buttonText}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>



                        {/* Continue rendering the other sections... */}
                    </div>
                </div>
            </section>



        </div>
    )
}

export default PointsAndRankup