import './App.css';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
// import Dashboard from './Pages/Dashboard';
import Layout1 from './Layout/Layout1';
import Dashboard from './Component/Dashboard';
import PVReport from './Component/PVReport';
import PointsAndRankup from './Component/Points&Rankup';
import Genealogy from './Component/Genealogy';
import InactivityReport from './Component/InactivityReport';
import RankAdvancement from './Component/RankAdvancement';
import AuthLayout from './Layout/AuthLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Login from "./Login"
import Register from "./Register"
import AdminLayout from './Layout/AdminLayout';
import { loadUser } from './slices/userSlice';
import Users from './Admin/Pages/Users';
import Profile from './Component/Profile';

function App() {

  const dispatch = useDispatch()
  const { isAuthenticated, error, isLoading, userData } = useSelector((state) => state.userCustom)

  useEffect(() => {
    dispatch(loadUser())
  }, [])


  return (
    <Router>
      <Routes>
        <Route exact path="/*" element={<AuthLayout />}>
          <Route path="" element={<Login userData={userData} />} />
          {/* <Route path="admin/secret/register" element={<Register />} /> */}
        </Route>

        {/* Second Route */}
        {userData && (userData.role === "user" || userData.role === "primeUser")  ? (
          <Route exact path={`/user/*`} element={<Layout1 userData={userData} />}>
            <Route path="" element={<Dashboard userData={userData} />} />
            <Route path="dashboard" element={<Dashboard userData={userData} />} />
            {/* <Route path="report/pv" element={<PVReport userData={userData} />} /> */}
            {/* <Route path="report/genealogy" element={<Genealogy userData={userData} />} /> */}
            <Route path="report/points&rankup" element={<PointsAndRankup userData={userData} />} />
            {/* <Route path="report/RankAdvancement" element={<RankAdvancement userData={userData} />} /> */}
            <Route path="profile" element={<Profile userData={userData} />} />

          </Route>

        ) : (
          <Route exact path={`/admin/*`} element={<AdminLayout userData={userData} />}>
            <Route path="" element={<Dashboard userData={userData} />} />
            <Route path="dashboard" element={<Dashboard userData={userData} />} />
            <Route path="report/pv" element={<PVReport userData={userData} />} />
            {/* <Route path="report/genealogy" element={<Genealogy userData={userData} />} /> */}
            <Route path="report/points&rankup" element={<PointsAndRankup userData={userData} />} />
            <Route path="report/RankAdvancement" element={<RankAdvancement userData={userData} />} />
            <Route path="users" element={<Users userData={userData} />} />
            <Route path="profile" element={<Profile userData={userData} />} />
          </Route>
        )}

      </Routes>
    </Router>
  );
}

export default App;
