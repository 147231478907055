import React, { useEffect, useState } from 'react';
import { TrashIcon, UserIcon } from "@heroicons/react/24/solid";
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import {
    IconButton,
    Tooltip,
} from "@material-tailwind/react";
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import LoginIcon from '@mui/icons-material/Login';
import {
    Popover,
    PopoverHandler,
    PopoverContent,
    Button,
} from "@material-tailwind/react";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, editRoleOfUser, getAllUsers, loginUserByAdmin, editRoleOfPrimeUser } from '../../slices/userSlice';

const MembersTable = () => {
    const dispatch = useDispatch()
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [currentFilter, setCurrentFilter] = useState('all'); // Default filter is 'all'
    const [sortOrder, setSortOrder] = useState('asc'); // Default sort order is 'asc'
    const itemsPerPage = 20;

    const { allUsers, userData } = useSelector((state) => state.userCustom)

    useEffect(() => {
        dispatch(getAllUsers())
    }, [])



    const handleDltMember = (id) => {
        // console.log(id)
        dispatch(deleteUser({ id })).then(() => dispatch(getAllUsers()))
    }
    const handleMakeAdmin = (id) => {
        console.log(id)
        dispatch(editRoleOfUser({ id })).then(() => dispatch(getAllUsers()))
    }
    const handleMakePrimeUser = (id) => {
        console.log(id)
        dispatch(editRoleOfPrimeUser({ id })).then(() => dispatch(getAllUsers()))
    }


    // Filter members based on search query and current filter
    const filteredMembers = allUsers?.filter((member) =>
        (member?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            member?.email?.toLowerCase().includes(searchQuery.toLowerCase()) ||
            member?.city?.toLowerCase().includes(searchQuery.toLowerCase())) &&
        (currentFilter === 'all' || member?.status?.toLowerCase() === currentFilter)
    );

    // Sort members based on createdAt and sortOrder
    const sortedMembers = filteredMembers?.sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        if (sortOrder === 'asc') {
            return dateA - dateB;
        } else {
            return dateB - dateA;
        }
    });

    // Calculate the total number of pages
    const totalPages = Math.ceil(sortedMembers?.length / itemsPerPage);

    // Change page
    const handlePageChange = (pageNumber) => {
        if (pageNumber < 1 || pageNumber > totalPages) {
            return;
        }
        setCurrentPage(pageNumber);
    };

    // Handle filter change
    const handleFilter = (filter) => {
        setCurrentFilter(filter);
        setCurrentPage(1); // Reset current page when changing the filter
    };

    // Handle sort order change
    const handleSortOrder = () => {
        const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
        setSortOrder(newSortOrder);
    };

    // Calculate the index of the last and first items on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // Get the current items to display
    const currentItems = sortedMembers?.slice(indexOfFirstItem, indexOfLastItem);

    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, startPage + 4);
    return (
        <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
            <div style={{ rowGap: '20px' }} className="flex justify-center md:justify-between items-center flex-wrap mb-4">

                <div className="mb-4 w-100 flex justify-end">
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search Members..."
                        className="px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#D1A4AC] focus:border-[#D1A4AC]"
                    />
                    <button
                        className="ml-2 px-4 py-2 text-sm font-medium bg-[#D1A4AC] text-white rounded-md focus:outline-none"
                        onClick={handleSortOrder}
                    >
                        {sortOrder === 'asc' ? 'Latest' : 'Oldest'}
                    </button>
                </div>
            </div>
            <table className="w-full whitespace-nowrap">
                <thead>
                    <tr className="h-16 w-full text-md leading-none text-gray-800">
                        <th className="font-normal text-left pl-4">SR.No</th>
                        <th className="font-normal text-left pl-12">User</th>
                        <th className="font-normal text-left pl-12">Password</th>
                        <th className="font-normal text-left pl-12">Active Plan</th>
                        <th className="font-normal text-left pl-12">Role</th>
                        <th className="font-normal text-left pl-12">Phone No</th>
                        <th className="font-normal text-left pl-12">Address</th>
                        <th className="font-normal text-left pl-16">Pay Amount</th>
                        <th className="font-normal text-left pl-16">Transaction Id</th>
                        <th className="font-normal text-left pl-16">Transaction Date</th>
                        <th className="font-normal text-left pl-16">Action</th>
                        <th className="px-7  2xl:px-0"></th>
                    </tr>
                </thead>
                <tbody className="w-full">
                    {currentItems?.map((member, index) => (
                        <tr
                            key={member._id}
                            className="h-16 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-200"
                        >
                            <td className="pl-8">
                                <p className="text-sm font-medium leading-none text-gray-800">
                                    {index + 1}
                                </p>
                            </td>
                            <td className="pl-4 cursor-pointer">
                                <div className="flex items-center">

                                    <div className="pl-8">
                                        <p className="font-medium">{member.name ? member.name : "N/A"}</p>
                                        <p className="text-xs leading-3 text-gray-600 pt-2">
                                            {member.email}
                                        </p>
                                    </div>
                                </div>
                            </td>
                            <td className="pl-12">
                                <p className="text-sm font-medium leading-none text-gray-800">
                                    {member?.password}
                                </p>
                            </td>
                            <td className="pl-12">
                                <p className="text-sm font-medium leading-none text-gray-800">
                                    {member.plan ? member.plan : "N/A"}
                                </p>
                            </td>
                            <td className="pl-12">
                                <p className="text-sm font-medium leading-none text-gray-800">
                                    {member.role}
                                </p>
                            </td>
                            <td className="pl-12">
                                <p className="text-sm font-medium leading-none text-gray-800">
                                    {member.phone ? member.phone : "N/A"}
                                </p>
                            </td>


                            <td className="pl-12">
                                <p className="text-sm font-medium leading-none text-gray-800">
                                    {member.address ? member.address : "N/A"}


                                </p>
                            </td>


                            <td className="pl-16">
                                <p className="text-sm font-medium leading-none text-gray-800 uppercase">
                                    {member?.amount ? member.amount / 100 : "N/A"} {member.currency}
                                </p>
                            </td>
                            <td className="pl-16">
                                <p className="text-sm font-medium leading-none text-gray-800">
                                    {member?.transactionId}
                                </p>
                            </td>
                            <td className="pl-16">
                                <p className="text-sm font-medium leading-none text-gray-800">
                                    {member?.transactionDate?.slice(0, 10)}
                                </p>
                            </td>
                            <td className="pl-16">
                                {
                                    member?.role === "user" ?
                                    <>
                                       <i style={{ fontSize: "17px" }} onClick={() => handleMakeAdmin(member?._id)} className='fas fa-user cursor-pointer mx-2'></i>
                                       <i style={{ fontSize: "17px" }} onClick={() => handleMakePrimeUser(member?._id)} className=' fa-solid fa-user-tie cursor-pointer mx-2'></i>
                                    </>

                                        :
                                        member?.role === "primeUser" ?
                                        <>
                                         <i style={{ fontSize: "17px" }} onClick={() => handleMakeAdmin(member?._id)} className='fas fa-user cursor-pointer mx-2'></i>
                                       {/* <i style={{ fontSize: "17px" }} onClick={() => handleMakePrimeUser(member?._id)} className=' fa-solid fa-user-tie cursor-pointer mx-2'></i> */}
                                        
                                        </>
                                            :
                                            <i style={{ fontSize: "17px" }} onClick={() => handleMakeAdmin(member?._id)} className='fa-solid fa-user-secret cursor-pointer mx-2'></i>
                                }
                                {
                                    member?.role === "user" || member?.role === "primeUser" ?
                                        <i style={{ fontSize: "17px" }} onClick={() => handleDltMember(member?._id)} className='fa-solid fa-trash mx-2 cursor-pointer'></i>
                                        : null
                                }

                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="flex justify-center mt-5">
                <div className="flex">
                    {/* <p className="text-[#D1A4AC]">Total Pages -</p> */}
                    {currentPage > 1 && (
                        <button
                            className="px-3 py-1 text-sm font-medium mx-1 rounded-md focus:outline-none text-[#D1A4AC]"
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            Previous
                        </button>
                    )}
                    {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                        <button
                            key={startPage + index}
                            className={`px-3 py-1 text-sm font-medium mx-1 rounded-md focus:outline-none ${currentPage === startPage + index
                                ? 'bg-[#D1A4AC] text-white'
                                : 'text-[#D1A4AC]'
                                }`}
                            onClick={() => handlePageChange(startPage + index)}
                        >
                            {startPage + index}
                        </button>
                    ))}
                    {currentPage < totalPages && (
                        <button
                            className="px-3 py-1 text-sm font-medium mx-1 rounded-md focus:outline-none text-[#D1A4AC]"
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            Next
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MembersTable;
